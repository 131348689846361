import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, ObservableInput, catchError, finalize, of, retry } from 'rxjs';
import { LoadingService } from './_services/loading-service/loading.service';
import { LoggingService } from '@tdoe/design-system';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService,
              private logger: LoggingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loadingService.show();
    return next.handle(request).pipe(
      finalize(() => this.loadingService.hide()),
      retry(1),
      catchError((returnedError): ObservableInput<any> => {
        this.logger.error('Please see console for more info', {
            title: 'HTTP Error',
            data: {
              returnedError,
              request
            },
            notify: true
        });
        return of(returnedError);
      })
    );
  }
}
