<mat-toolbar>
    <button [routerLink]="['/']" [relativeTo]="route.parent" [state]="{ savedState }" mat-flat-button>
        <mat-icon class="button-icon-left">arrow_back</mat-icon>
        <span class="button-text-right">Back</span>
    </button>
</mat-toolbar>
<div class="course-detail">
    <h1 class="mat-elevation-z8 widget" [innerHTML]="course.courseName ?? 'Loading course...'"></h1>
    <div class="flex-col">
        <div class="course-info mat-elevation-z8 widget">
            <dl>
                <dt>Course code</dt>
                <dd [innerHTML]="course.courseCode"></dd>

                <dt>Status</dt>
                <dd [innerHTML]="course.publishState"></dd>

                <dt>Type</dt>
                <dd [innerHTML]="course.courseType"></dd>

                <dt>Subject</dt>
                <dd [innerHTML]="course.contentArea"></dd>

                <dt>Grades</dt>
                <dd [innerHTML]="grades"></dd>

                <dt>Endorsements</dt>
                <dd [innerHTML]="endorsements"></dd>

                <dt *ngIf="course.previousCourseCode">Previous Code</dt>
                <dd *ngIf="course.previousCourseCode" [innerHTML]="course.previousCourseCode"></dd>
                
                <dt *ngIf="course.creditWeight">Credit weight</dt>
                <dd *ngIf="course.creditWeight" [innerHTML]="course.creditWeight | number: '1.1-2'"></dd>
                
                <dt *ngIf="course.termWeight">Term weight</dt>
                <dd *ngIf="course.termWeight" [innerHTML]="course.termWeight | number: '1.1-2'"></dd>
                
            </dl>
            <a [href]="course.courseStandardsUrl" *ngIf="course.courseStandardsUrl">View standards
                <mat-icon class="link-icon">open_in_new</mat-icon>
            </a>
        </div>
        <div class="course-details mat-elevation-z8 widget">
            <mat-tab-group>
                <mat-tab label="Description">
                    <p [innerHTML]="course.courseDescription ?? 'No description provided'"></p>
                </mat-tab>
                <mat-tab [label]="'Attributes (' + (course.attributes?.length ?? 0) + ')'">
                    <dl *ngIf="(course.attributes?.length ?? 0) > 0;else noAttributes">
                        <ng-container *ngFor="let item of course.attributes">
                            <dt [innerHTML]="item.name"></dt>
                            <dd [innerHTML]="item.value"></dd>
                        </ng-container>
                    </dl>
                    <ng-template #noAttributes>
                        <div class="empty-text">No attributes to show</div>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'Districts (' + (course.districts?.length ?? 0) + ')'">
                    <dl *ngIf="(course.districts?.length ?? 0) > 0;else noDistricts">
                        <ng-container *ngFor="let item of course.districts">
                            <dt [innerHTML]="item.name"></dt>
                            <dd [innerHTML]="item.code"></dd>
                        </ng-container>
                    </dl>
                    <ng-template #noDistricts>
                        <div class="empty-text">No districts to show</div>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'Notes (' + (course.notes?.length ?? 0) + ')'">
                    <dl *ngIf="(course.notes?.length ?? 0) > 0;else noNotes">
                        <ng-container *ngFor="let item of course.notes">
                            <dt [innerHTML]="item.name"></dt>
                            <dd [innerHTML]="item.value"></dd>
                        </ng-container>
                    </dl>
                    <ng-template #noNotes>
                        <div class="empty-text">No notes to show</div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="widget mat-elevation-z8 full-width-footer">
        <h3>Change log</h3>
        <ul class="tdoe-card-list" *ngIf="hasChanges;else noChanges">
            <li *ngIf="course.publishedChanges?.addedAttributes">
                <span>Attributes added:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.addedAttributes"></span>
            </li>
            <li *ngIf="course.publishedChanges?.addedDistricts">
                <span>Districts added:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.addedDistricts"></span>
            </li>
            <li *ngIf="course.publishedChanges?.addedEndorsements">
                <span>Endorsements added:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.addedEndorsements"></span>
            </li>
            <li *ngIf="course.publishedChanges?.addedGrades">
                <span>Grades added:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.addedGrades"></span>
            </li>
            <li *ngIf="course.publishedChanges?.addedNotes">
                <span>Notes added:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.addedNotes"></span>
            </li>
            <li *ngIf="course.publishedChanges?.removedAttributes">
                <span>Attributes removed:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.removedAttributes"></span>
            </li>
            <li *ngIf="course.publishedChanges?.removedDistricts">
                <span>Districts removed:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.removedDistricts"></span>
            </li>
            <li *ngIf="course.publishedChanges?.removedEndorsements">
                <span>Endorsements removed:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.removedEndorsements"></span>
            </li>
            <li *ngIf="course.publishedChanges?.removedGrades">
                <span>Grades removed:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.removedGrades"></span>
            </li>
            <li *ngIf="course.publishedChanges?.removedNotes">
                <span>Notes removed:&nbsp;</span>
                <span [innerHTML]="course.publishedChanges?.removedNotes"></span>
            </li>
        </ul>
        <ng-template #noChanges>
            <div class="empty-text">No changes to show</div>
        </ng-template>
</div>