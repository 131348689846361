import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { AcademicYearDto } from 'src/app/_models/academicYearDto';
import { AttributeDto } from 'src/app/_models/attributeDto';
import { ContentAreaDto } from 'src/app/_models/contentAreasDto';
import { CourseSearchDto } from 'src/app/_models/courseSearchDto';
import { CourseSearchQueryModel } from 'src/app/_models/courseSearchQueryModel';
import { CourseSearchResultsDto } from 'src/app/_models/courseSearchResultsDto';
import { CourseTypeDto } from 'src/app/_models/courseTypeDto';
import { DistrictDto } from 'src/app/_models/districtDto';
import { EndorsementsDto } from 'src/app/_models/endorsementsDto';
import { GradeDto } from 'src/app/_models/gradesDto';
import { NoteTypeDto } from 'src/app/_models/noteTypeDto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  export(query: CourseSearchQueryModel, format: string): Promise<any> {
    return firstValueFrom(this.http.post(`${environment.apiUrl}/search/export/${format}`, query, { responseType: 'arraybuffer' }));
  }

  fetchCourse(academicYearId: number, courseCode: string, courseId: number): Promise<CourseSearchDto> {
    return firstValueFrom(this.http.get<CourseSearchDto>(`${environment.apiUrl}/search/catalog/${academicYearId}/courses/${courseCode}?courseID=${courseId}`));
  }

  getCurrentAcademicYear(): Promise<AcademicYearDto> {
    return firstValueFrom(this.http.get<AcademicYearDto>(`${environment.apiUrl}/search/getcurrentacademicyear`));
  }

  getLastPublishDate(academicYearId: number): Promise<{ result: string }> {
    return firstValueFrom(this.http.get<{ result: string }>(`${environment.apiUrl}/search/getlastpublishdate/${academicYearId}`));
  }

  listAcademicYears(): Promise<AcademicYearDto[]> {
    return firstValueFrom(this.http.get<AcademicYearDto[]>(`${environment.apiUrl}/search/listacademicyears`));
  }

  search(query: CourseSearchQueryModel): Promise<CourseSearchResultsDto> {
    return firstValueFrom(this.http.post<CourseSearchResultsDto>(`${environment.apiUrl}/search`, query));
  }

  getAllGrades(academicYearId: number): Observable<GradeDto[]> {
    const apiUrl = `${environment.apiUrl}/masterdata/getgrades/${academicYearId}`;
    return this.http.get<GradeDto[]>(encodeURI(apiUrl));
  }

  getCourseTypes(academicYearId: number): Observable<CourseTypeDto[]> {
    const apiUrl = `${environment.apiUrl}/masterdata/getcoursetypes/${academicYearId}`;
    return this.http.get<CourseTypeDto[]>(encodeURI(apiUrl));
  }

  getEndorsements(academicYearId: number): Observable<EndorsementsDto[]> {
    const apiUrl = `${environment.apiUrl}/masterdata/getendorsements/${academicYearId}`;
    return this.http.get<EndorsementsDto[]>(encodeURI(apiUrl));
  }

  getAllContentAreas(academicYearId: number): Observable<ContentAreaDto[]> {
    const apiUrl = `${environment.apiUrl}/masterdata/getcontentareas/${academicYearId}`;
    return this.http.get<ContentAreaDto[]>(encodeURI(apiUrl));
  }

  getAttributes(academicYearId: number): Observable<AttributeDto[]> {
    const apiUrl = `${environment.apiUrl}/masterdata/getattributes/${academicYearId}`;
    return this.http.get<AttributeDto[]>(encodeURI(apiUrl));
  }

}
