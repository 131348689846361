import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseSearchDto } from '../_models/courseSearchDto';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../_services/api-service/api.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {
  course!: CourseSearchDto;
  savedState?: any;

  get endorsements(): string {
    if ((this.course?.endorsements?.length ?? 0) === 0) return 'N/A';
    const result = this.course?.endorsements?.map(c => `${c.code} - ${c.name}`).join(', ');
    return result ?? 'N/A';
  }

  get grades(): string {
    if ((this.course?.grades?.length ?? 0) === 0) return 'N/A';
    const result = this.course?.grades?.map(c => c.name).join(', ');
    return result ?? 'N/A';
  }

  get hasChanges(): boolean {
    const _ = this.course.publishedChanges;
    if (_) {
      if (_.addedAttributes) return true;
      if (_.addedDistricts) return true;
      if (_.addedEndorsements) return true;
      if (_.addedGrades) return true;
      if (_.addedNotes) return true;
      if (_.removedAttributes) return true;
      if (_.removedDistricts) return true;
      if (_.removedEndorsements) return true;
      if (_.removedGrades) return true;
      if (_.removedNotes) return true;
    }
    return false;
  }

  constructor(router: Router,
              public route: ActivatedRoute,
              private apiService: ApiService) {
              
    const state = router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.course = state['course'];
      this.savedState = state['savedState'];
    }
  }

  async ngOnInit(): Promise<void> {
      if (this.course) return;

      const p = await firstValueFrom(this.route.params);
      if (p?.courseId && p?.courseCode && p?.academicYearId) {
        this.course = await this.apiService.fetchCourse(p.academicYearId, p.courseCode, p.courseId);
      }
  }

}
